<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Convenios -->
            <v-col
              cols="6"
              md="6"
              sm="6"
              class="py-0"
              v-if="comisionConcepto == null"
            >
              <v-select
                outlined
                clearable
                dense
                item-text="value"
                item-value="id"
                multiple
                v-model="conveniosSelected"
                :rules="[conveniosSelected.length != 0 || 'Campo requerido']"
                return-object
                label="Convenios (*)"
                :items="convenios"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="conveniosToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="conveniosSelected.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIconConvenios }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ conveniosSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col v-else cols="6" md="6" sm="6" class="py-0">
              <v-select
                outlined
                clearable
                dense
                item-text="value"
                item-value="id"
                return-object
                v-model="convSelected"
                :rules="rules.required"
                label="Convenio (*)"
                :items="convenios"
              >
              </v-select>
            </v-col>
            <!-- Conceptos -->
            <v-col
              cols="6"
              md="6"
              sm="6"
              class="py-0"
              v-if="comisionConcepto == null"
            >
              <v-select
                outlined
                clearable
                dense
                item-text="value"
                item-value="id"
                multiple
                :rules="[conceptosSelected.length != 0 || 'Campo requerido']"
                v-model="conceptosSelected"
                return-object
                label="Conceptos (*)"
                :items="conceptos"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="conceptosToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="conceptosSelected.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIconConceptos }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ conceptosSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col v-else cols="6" md="6" sm="6" class="py-0">
              <v-select
                outlined
                clearable
                dense
                item-text="value"
                item-value="id"
                v-model="concSelected"
                :rules="rules.required"
                return-object
                label="Concepto (*)"
                :items="conceptos"
              >
              </v-select>
            </v-col>
            <!-- Comisión -->
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                label="Comisión (*)"
                v-model="comision"
                append-icon="mdi-percent-outline"
                dense
                onkeypress="return (event.charCode == 44 || event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57))"
                outlined
                :rules="
                  rules.required.concat([
                    comision <= 100 || 'Formato incorrecto.'
                  ])
                "
              >
              </v-text-field>
            </v-col>
            <!-- Vigencia desde -->
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(vigenciaDesde, vigenciaHasta)],
                    rules.periodoYyyyMm
                  )
                "
                v-mask="'######'"
                onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                label="Vigencia desde (*)"
                hint="Formato AAAAMM"
                persistent-hint
                v-model="vigenciaDesde"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
            <!-- Vigencia hasta -->
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(vigenciaDesde, vigenciaHasta)],
                    rules.periodoYyyyMm
                  )
                "
                v-mask="'######'"
                onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                label="Vigencia hasta (*)"
                hint="Formato AAAAMM"
                persistent-hint
                v-model="vigenciaHasta"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditComisionConcDesregulacion",
  directives: { mask },
  props: {
    comisionConcepto: {
      Type: Object,
      required: false
    }
  },
  data: () => ({
    formEditTitle: enums.titles.EDIT_COMISION_CONC_DESREGULACION,
    rules: rules,
    isFormValid: false,
    convenios: [],
    conveniosSelected: [],
    convSelected: null,
    conceptos: [],
    conceptosSelected: [],
    concSelected: null,
    comision: null,
    vigenciaDesde: null,
    vigenciaHasta: null,
    loadingSaveBtn: false
  }),
  created() {
    this.setSelects();
  },
  mounted() {
    if (this.comisionConcepto != null) this.setComisionConcepto();
    else this.formEditTitle = "Nueva comisión de concepto";
  },
  computed: {
    multiselectIconConvenios() {
      if (this.selectAllConvenios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllConvenios() {
      return (
        this.conveniosSelected &&
        this.conveniosSelected.length === this.convenios.length
      );
    },
    multiselectIconConceptos() {
      if (this.selectAllConceptos) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllConceptos() {
      return (
        this.conceptosSelected &&
        this.conceptosSelected.length === this.conceptos.length
      );
    }
  },
  methods: {
    ...mapActions({
      getConvenios: "afiliaciones/getConvenios",
      getConceptosDesregulacion: "convenios/getConceptosDesregulacion",
      saveComisionConcepto: "convenios/saveComisionConcepto",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const convenios = await this.getConvenios();
      this.convenios = convenios;
      const conceptos = await this.getConceptosDesregulacion();
      this.conceptos = conceptos;
    },
    setComisionConcepto() {
      this.convSelected = {
        id: this.comisionConcepto.osId,
        value: this.comisionConcepto.osNom
      };
      this.concSelected = {
        id: this.comisionConcepto.liqDesConcId,
        value: this.comisionConcepto.liqDesConcNom
      };
      this.vigenciaDesde = this.comisionConcepto.vigenciaDesde;
      this.vigenciaHasta = this.comisionConcepto.vigenciaHasta;
      this.comision = this.comisionConcepto.comision;
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      let convIds = [];
      let concIds = [];
      if (this.comisionConcepto != null) {
        convIds.push(this.convSelected);
        concIds.push(this.concSelected);
      }

      const data = {
        liqDesComisionId: this.comisionConcepto?.liqDesComisionId,
        liqDesConcId:
          this.comisionConcepto != null
            ? concIds.map(x => x.id)
            : this.conceptosSelected.map(x => x.id),
        osId:
          this.comisionConcepto != null
            ? convIds.map(x => x.id)
            : this.conveniosSelected.map(x => x.id),
        comision: this.comision,
        vigenciaDesde: this.vigenciaDesde,
        vigenciaHasta: this.vigenciaHasta
      };
      const response = await this.saveComisionConcepto(data);
      try {
        if (response.status === 200) {
          this.closeModal();
          this.setAlert({ type: "success", message: "Guardado con éxito." });
        }
        this.loadingSaveBtn = false;
      } catch {
        this.loadingSaveBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) {
          this.conveniosSelected = [];
        } else {
          this.conveniosSelected = this.convenios;
        }
      });
    },
    conceptosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConceptos) {
          this.conceptosSelected = [];
        } else {
          this.conceptosSelected = this.conceptos;
        }
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    }
  }
};
</script>

<style></style>
