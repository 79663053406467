var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[(_vm.comisionConcepto == null)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-select',{attrs:{"outlined":"","clearable":"","dense":"","item-text":"value","item-value":"id","multiple":"","rules":[_vm.conveniosSelected.length != 0 || 'Campo requerido'],"return-object":"","label":"Convenios (*)","items":_vm.convenios},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.conveniosToggle()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.conveniosSelected.length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.multiselectIconConvenios)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.conveniosSelected.length - 1)+" otros) ")]):_vm._e()]}}],null,false,2380381954),model:{value:(_vm.conveniosSelected),callback:function ($$v) {_vm.conveniosSelected=$$v},expression:"conveniosSelected"}})],1):_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-select',{attrs:{"outlined":"","clearable":"","dense":"","item-text":"value","item-value":"id","return-object":"","rules":_vm.rules.required,"label":"Convenio (*)","items":_vm.convenios},model:{value:(_vm.convSelected),callback:function ($$v) {_vm.convSelected=$$v},expression:"convSelected"}})],1),(_vm.comisionConcepto == null)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-select',{attrs:{"outlined":"","clearable":"","dense":"","item-text":"value","item-value":"id","multiple":"","rules":[_vm.conceptosSelected.length != 0 || 'Campo requerido'],"return-object":"","label":"Conceptos (*)","items":_vm.conceptos},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.conceptosToggle()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.conceptosSelected.length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.multiselectIconConceptos)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.conceptosSelected.length - 1)+" otros) ")]):_vm._e()]}}],null,false,3992046530),model:{value:(_vm.conceptosSelected),callback:function ($$v) {_vm.conceptosSelected=$$v},expression:"conceptosSelected"}})],1):_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-select',{attrs:{"outlined":"","clearable":"","dense":"","item-text":"value","item-value":"id","rules":_vm.rules.required,"return-object":"","label":"Concepto (*)","items":_vm.conceptos},model:{value:(_vm.concSelected),callback:function ($$v) {_vm.concSelected=$$v},expression:"concSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Comisión (*)","append-icon":"mdi-percent-outline","dense":"","onkeypress":"return (event.charCode == 44 || event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57))","outlined":"","rules":_vm.rules.required.concat([
                  _vm.comision <= 100 || 'Formato incorrecto.'
                ])},model:{value:(_vm.comision),callback:function ($$v) {_vm.comision=$$v},expression:"comision"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"type":"text","outlined":"","dense":"","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.vigenciaDesde, _vm.vigenciaHasta)],
                  _vm.rules.periodoYyyyMm
                ),"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","label":"Vigencia desde (*)","hint":"Formato AAAAMM","persistent-hint":"","autocomplete":"off"},model:{value:(_vm.vigenciaDesde),callback:function ($$v) {_vm.vigenciaDesde=$$v},expression:"vigenciaDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"type":"text","outlined":"","dense":"","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.vigenciaDesde, _vm.vigenciaHasta)],
                  _vm.rules.periodoYyyyMm
                ),"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","label":"Vigencia hasta (*)","hint":"Formato AAAAMM","persistent-hint":"","autocomplete":"off"},model:{value:(_vm.vigenciaHasta),callback:function ($$v) {_vm.vigenciaHasta=$$v},expression:"vigenciaHasta"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"loading":_vm.loadingSaveBtn,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }