<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
      <v-container>
        <v-row class="pr-4">
          <v-col
            cols="12"
            md="10"
            class="py-0"
            align="left"
            @click="showFilters = true"
          >
            <FiltersSelected :filters="filtersApplyed" v-if="!showFilters" />
          </v-col>
          <v-col
            cols="12"
            md="2"
            align="right"
            align-self="center"
            class="py-0"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                  @click.stop="showFilters = !showFilters"
                >
                  {{ closeFiltersIcon }}
                </v-icon>
              </template>
              <span>{{
                !showFilters ? "Mostrar filtros" : "Ocultar filtros"
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="loadConfigsComisiones()"
          >
            <v-row>
              <!-- Vigente al período -->
              <v-col cols="12" sm="6" md="3" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  label="Vigente al período"
                  hint="Formato AAAAMM"
                  v-mask="'######'"
                  :rules="rules.periodoYyyyMm"
                  v-model="vigenteAlPeriodo"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pr-4 pl-4" justify="end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    size="28"
                    @click="resetForm"
                  >
                    {{ clearFiltersIcon }}
                  </v-icon>
                </template>
                <span>Limpiar filtros</span>
              </v-tooltip>
              <v-btn
                color="primary"
                elevation="2"
                small
                :disabled="!isFormValid"
                type="submit"
                form="filters-form"
              >
                Aplicar
              </v-btn>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="comisionesConceptos"
        class="elevation-1"
        :search="search"
        :loading="loading"
        item-key="liqDesComisionId"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4" align="end">
                <v-btn
                  color="primary"
                  class="to-right"
                  v-if="canCreate"
                  @click="openModalEditComision()"
                >
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left v-if="canEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModalEditComision(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar comisión</span>
          </v-tooltip>
          <v-tooltip left v-if="canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteComision(item.liqDesComisionId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar comisión</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="60%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditComisionConcDesregulacion
        :comisionConcepto="comisionConcepto"
        @closeAndReload="closeAndReload"
      ></EditComisionConcDesregulacion>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDeleteComision()"
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import EditComisionConcDesregulacion from "@/components/modules/convenios/procesosDesregulacion/EditComisionConcDesregulacion.vue";

export default {
  name: "ConfigComisionesConcDesregulacion",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    FiltersSelected,
    EditComisionConcDesregulacion,
  },
  directives: { mask },
  created() {
    this.loadConfigsComisiones();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  data: (vm) => ({
    routeToGo: "ConfigProcesosDesregulacion",
    title: enums.titles.CONFIG_COMISIONES_CONC_DESREGULACION,
    search: "",
    titleDelete: "¿Desea eliminar la comisión?",
    rules: rules,
    searchIcon: enums.icons.SEARCH,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    isFormValid: true,
    loading: false,
    filtersApplyed: [],
    showFilters: true,
    comisionesConceptos: [],
    headers: [
      {
        text: "Concepto",
        align: "start",
        value: "liqDesConcNom",
        sortable: false,
      },
      {
        text: "Convenio",
        align: "start",
        value: "osNom",
        sortable: false,
      },
      {
        text: "Comisión",
        align: "end",
        value: "comision",
        sortable: false,
      },
      {
        text: "Vigencia desde",
        align: "start",
        value: "vigenciaDesde",
        sortable: false,
      },
      {
        text: "Vigencia hasta",
        align: "start",
        value: "vigenciaHasta",
        sortable: false,
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    vigenteAlPeriodo: null,
    showDeleteModal: false,
    allowedActions: null,
    canCreate: false,
    canEdit: false,
    canDelete: false,
    openModalEdit: false,
    comisionConcepto: null,
  }),
  methods: {
    ...mapActions({
      getComisionesConceptos: "convenios/getComisionesConceptos",
      deleteComisionConcepto: "convenios/deleteComisionConcepto",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_COMISION_CONCEPTO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_COMISION_CONCEPTO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_COMISION_CONCEPTO:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadConfigsComisiones() {
      this.comisionesConceptos = [];
      this.loading = true;
      this.customizeFiltersApplied();
      try {
        const comisiones = await this.getComisionesConceptos();
        if (this.vigenteAlPeriodo != null && this.vigenteAlPeriodo != "") {
          this.comisionesConceptos = comisiones.filter(
            (x) =>
              Number(x.vigenciaDesde) <= this.vigenteAlPeriodo &&
              Number(x.vigenciaHasta) >= this.vigenteAlPeriodo
          );
        } else this.comisionesConceptos = comisiones;
        this.showFilters = false;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      if (this.vigenteAlPeriodo) {
        this.filtersApplyed.splice(0, 1, {
          key: "vigenteAlPeriodo",
          label: "Vigente al período",
          model: this.vigenteAlPeriodo,
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    deleteComision(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDeleteComision() {
      const response = await this.deleteComisionConcepto(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadConfigsComisiones();
      }
    },
    openModalEditComision(item) {
      this.comisionConcepto = item;
      this.openModalEdit = true;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadConfigsComisiones();
    },
  },
};
</script>

<style></style>
